import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';

import { updateEvent } from '@src/api/event';
import { saveUser } from '@src/api/login';
import { createOrder, getOrders, updateOrderById, verifyPromo } from '@src/api/order';
import { getPackageById } from '@src/api/package';
import { PromoDetailProps } from '@src/components/EventDetailCard/types';
import { createSelectResultData } from '@src/components/FormComponent/Select';
import { FormValues } from '@src/components/FormComponent/types';
import { useFetchSettings } from '@src/hooks/useFetchSettings';
import { getOrderStatusNameById, getPaymenStatusById, statusCodes, UiRoutes } from '@src/lib/constants';
import { getOptions } from '@src/lib/getOptions';
import { getUpdateBody } from '@src/lib/getUpdate';
import { getTotalAmount, getUnsetData, groupByBookingDateAndTime } from '@src/lib/helper';
import { Booking } from '@src/model/Event';
import { Order } from '@src/model/Order';
import Package from '@src/model/Package';
import { Settings } from '@src/model/Setting';
import { updateInvoiceAction } from '@src/redux/action/invoiceDetails';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';

const useOrderSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: eventId } = useParams();
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const [promoDetails, setPromoDetails] = useState<PromoDetailProps>({
    isApplied: false,
    success: false,
  });

  const [packageData, setPackageData] = useState<Package>();
  const [orderDetails, setOrderDetails] = useState<Order>();
  const [eventFormatData, setEventFormatData] = useState<Record<string, Booking[]>>({});
  const [editDiscount, setEditDiscount] = useState(false);
  const [editService, setEditService] = useState(false);
  const [editDelivery, setEditDelivery] = useState(false);
  const [servicePercentage, setServicePercentage] = useState<number>(0);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const { data: settings } = useFetchSettings<Settings>({});

  const eventDetails = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const { vat, serviceFee, discount } = useSelector((state: ReduxProps) => state?.invoiceDetailsReducer);

  //admin changes
  const { _id: userId, company, email, phoneNumber } = useSelector((state: ReduxProps) => state?.clientDetailsReducer);
  const { Bookings, makeMyOwn, startDate, endDate, orderId, packageDetails, _id } = eventDetails;
  const firstName = eventDetails?.contactDetails?.name?.firstName as string;
  const lastName = eventDetails?.contactDetails?.name?.lastName as string;

  const serviceType = packageDetails?.service;

  const totalAmount = getTotalAmount(Bookings as Booking[], makeMyOwn);

  const handlePromoApply = async (promoCode: string) => {
    const promoFilter = {
      customer: { _id: userId, firstName: firstName, lastName: lastName, email },
      eventId: eventId,
      company: { name: company },
      promo: promoCode,
    };

    try {
      if (promoCode) {
        const result = await verifyPromo({ filter: promoFilter });

        if (result?.status === statusCodes.API_SUCCESS_CODE) {
          setPromoDetails(prev => {
            return {
              ...prev,
              isApplied: true,
              discountAmount: result?.data?.discountAmount,
              _id: result?.data?._id,
              discount: result?.data?.discount,
              discountType: result?.data?.discountType,
              success: true,
              message: '',
            };
          });
          result?.data?.discountAmount &&
            dispatch(
              updateInvoiceAction({
                discountAmount: result?.data?.discountAmount,
                discount: result?.data?.discount,
                discountType: result?.data?.discountType,
              })
            );
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ message?: string }>;
      const status = axiosError?.response?.status;
      const message = axiosError?.response?.data?.message;

      if (status === 400) {
        setPromoDetails(prev => {
          return {
            ...prev,
            isApplied: true,
            message: message,
            success: false,
            discountAmount: 0,
            _id: undefined,
            discount: 0,
          };
        });
        dispatch(
          updateInvoiceAction({
            discountAmount: 0,
            discount: 0,
            discountType: '',
          })
        );
      }
    }
  };

  // eslint-disable-next-line complexity
  const handleOrderUpdation = async ({
    isSaveForLater = false,
    isCheckBoxCheck,
    orderData,
  }: {
    isSaveForLater?: boolean;
    isCheckBoxCheck?: boolean;
    orderData?: Record<string, unknown>;
  }) => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      const data = {
        eventId: eventId,
        promoId: editDiscount ? undefined : promoDetails?._id,
        totalAmount: totalAmount,
        orderType: orderDetails?.orderType ? orderDetails?.orderType : 'PERSONALLY',
        customer: { _id: userId, firstName, lastName, email },
        //  admin changes
        ...(isSaveForLater
          ? { finalConfirmation: false }
          : { finalConfirmation: true, orderStatus: { id: 2, name: 'QUOTE' } }),
        orderUrl: process.env.REACT_APP_BASE_URL + '/order-summary/' + eventId + '?button=true',
        paymentRequired: isCheckBoxCheck,
        discountPercentage: promoDetails?._id || !editDiscount ? undefined : Number(discount),
        ...(serviceType === 'buffet'
          ? { servicePercentage: editService ? (servicePercentage as number) : undefined }
          : { deliveryCost: deliveryFee }),
        ...(orderData ? orderData : {}),
      };

      const unsetPromoResponse =
        orderDetails?._id && editDiscount && discount === 0 && orderId?.promoId?._id
          ? await updateOrderById(getUpdateBody({ unsetData: { promoId: 1 } }), orderDetails?._id)
          : { _id: orderDetails?._id };

      const unsetData = getUnsetData({ values: data, keys: ['poNumber'] });

      const result =
        orderDetails?._id && unsetPromoResponse
          ? await updateOrderById(getUpdateBody({ setData: data, unsetData: unsetData }), orderDetails?._id)
          : await createOrder(data as unknown as Order);

      if (
        (result?.status == statusCodes.API_CREATE_SUCCESS_CODE || result?.status == statusCodes.API_SUCCESS_CODE) &&
        result?._id &&
        !isSaveForLater
      ) {
        result?._id && navigate(UiRoutes.THANKYOU.split(':id')[0] + result?._id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const handleCheckOutClick = async (data: {
    isSaveForLater?: boolean;
    isCheckBoxCheck?: boolean;
    checkBookingStatus?: boolean;
  }) => {
    let orderData;

    if (data?.checkBookingStatus) {
      if (!formikRef.current) return;

      const values = formikRef.current.values;

      const paymentStatusId = createSelectResultData({ value: values?.paymentStatus });
      const orderStatusId = createSelectResultData({ value: values?.orderStatus });

      orderData = {
        paymentStatus: {
          id: paymentStatusId,
          name: getPaymenStatusById(paymentStatusId as number),
        },
        orderStatus: {
          id: orderStatusId,
          name: getOrderStatusNameById(orderStatusId as number),
        },
        poNumber: (values?.poNumber && (values?.poNumber as string).trim()) || undefined,
        document: values?.document,
        creditNote: values?.creditNote ? values?.creditNote : undefined,
        refundedAmount: values?.refundedAmount ? values?.refundedAmount : undefined,
      };

      await updateEvent(
        getUpdateBody({
          setData: {
            kitchenNote: values?.kitchenNote,
          },
        }),
        eventId || ''
      );
    }

    handleOrderUpdation({ ...data, orderData });
  };

  const handleHeadingOut = useCallback(async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));
      await handleCheckOutClick({ isSaveForLater: true });

      navigate(UiRoutes.ORDER_MANAGEMENT);

      // KEEP HEADING OUT COMMENTED: This is to prevent clients from receiving sms when their orders are being managed by Sales Team

      // const headingOutData = {
      //   phoneNumber: phoneNumber ?? '',
      //   orderUrl: process.env.REACT_APP_BASE_URL + UiRoutes.ORDER_SUMMARY.replace(':id', eventId ?? ''),
      //   eventId: _id,
      // };

      // const verifyResult = phoneNumber && (await saveUser(headingOutData));

      // if (verifyResult?.status === statusCodes?.API_SUCCESS_CODE) {
      //   navigate(UiRoutes.ORDER_MANAGEMENT);
      // }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  }, [
    phoneNumber,
    eventId,
    UiRoutes,
    promoDetails,
    orderDetails,
    serviceFee,
    totalAmount,
    userId,
    firstName,
    lastName,
    deliveryFee,
  ]);

  const fetchOrderByEvent = useCallback(async () => {
    try {
      if (eventId) {
        const options = getOptions({ filter: [{ key: 'eventId', value: eventId }] });
        const orderResult = await getOrders(options);
        const data = orderResult?.data?.results?.length ? orderResult?.data?.results[0] : undefined;

        if (data) {
          setOrderDetails(data);
          data?.servicePercentage && setServicePercentage(data?.servicePercentage);
          data?.deliveryCost && setDeliveryFee(data?.deliveryCost);

          dispatch(
            updateInvoiceAction({
              discountAmount: data?.discountValue,
              discount: data?.promoId?.discount ? data?.promoId?.discount : data?.discountPercentage,
              discountType: data?.promoId?.discountType ?? 'percentage',
              promoCode: data?.promo,
              subTotal: data?.totalAmount,
              finalTotal: data?.finalAmount,
              deliveryCost: data?.deliveryCost,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [eventId]);

  const fetchPackageData = useCallback(async (packageId: string) => {
    try {
      const response = await getPackageById(packageId);

      if (response.status === statusCodes.API_SUCCESS_CODE) {
        const result = response?.data;
        result && setPackageData(result);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setPromoDetails(prev => {
      return { ...prev, handlePromoApply };
    });
  }, []);

  useEffect(() => {
    setEventFormatData(groupByBookingDateAndTime(Bookings || [], startDate, endDate));
  }, [Bookings]);

  return {
    formikRef,
    orderDetails,
    eventDetails,
    settings,
    packageData,
    promoDetails,
    eventFormatData,
    totalAmount,
    editDiscount,
    editService,
    editDelivery,
    servicePercentage,
    deliveryFee,
    setDeliveryFee,
    setServicePercentage,
    setEditDiscount,
    setEditService,
    setEditDelivery,
    handlePromoApply,
    handleCheckOutClick,
    handleHeadingOut,
    fetchOrderByEvent,
    fetchPackageData,
  };
};

export default useOrderSummary;
