/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { styled, Tab, tabClasses, Tabs } from '@mui/material';

import { createBooking, updateBookingById } from '@src/api/booking';
import { getDropoffMenu } from '@src/api/dropoffMenu';
import { getEventById } from '@src/api/event';
import { createPackagesEvent } from '@src/api/package';
import ChooseMealHelmet from '@src/components/ChooseMealHelmet';
import DeleteModal from '@src/components/DeleteModal';
import MaxWidthContainer from '@src/components/MaxWidthContainer/MaxWidthContainer';
import CartModal from '@src/components/Modals/CartModal';
import OtpVerificationModal from '@src/components/Modals/OtpVerificationModal';
import SaveProgressModal from '@src/components/Modals/SaveProgressModal';
import OrderFooter from '@src/components/OrderFooter';
import OrderList from '@src/components/OrderList';
import useAuthentication from '@src/hooks/useAuthentication';
import { useFetchEventData } from '@src/hooks/usefetchEventData';
import { useFetchSettings } from '@src/hooks/useFetchSettings';
import { useIsMobile } from '@src/hooks/useIsMobile';
import { dateFormatter, DROP_OFF, getErrorMessage, isAddonCategory, statusCodes, UiRoutes } from '@src/lib/constants';
import { getUpdateBody } from '@src/lib/getUpdate';
import {
  BOXES_CATEGORY,
  capitalizeFirstLetterOnly,
  formatNumber,
  getDishPrice,
  getDishQuantity,
  getMealKeyFromIndex,
  getMealTypeIndex,
  getTotalAmount,
  isBoxCategory,
  isElementInViewport,
} from '@src/lib/helper';
import { dropoffHeaderIcon } from '@src/lib/imgUrl';
import { Box } from '@src/model/Box';
import { Category } from '@src/model/Category';
import { AddOn, Booking, CreateBookingProps, Dish, Event } from '@src/model/Event';
import { CreatePackagesEventParams } from '@src/model/Package';
import { Settings } from '@src/model/Setting';
import {
  BoxPayload,
  MealType,
  WeeklyAddonPayload,
  WeeklyDish,
  WeeklyDishPayload,
  WeeklyMenuOrder,
} from '@src/model/WeeklyMenu';
import { clearBookingAction, updateBookingAction } from '@src/redux/action/bookingDetails';
import { createEventAction, updateEventAction } from '@src/redux/action/eventDetails';
import { inOrderJourney } from '@src/redux/action/journeyStatus';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';
import { StyledMainWrapper } from '@src/styles/mainLayoutWrapper';
import { theme } from '@src/styles/theme';

import { StyledColumnOne, StyledContentWrapper } from '../OrderDetailScreen/styles';

import { DropoffFoodContainer } from './DropoffFoodContainer';
import { BreadCrumbContainer, BreadCrumbText, StyledCenteredFlexColumn, StyledColumnTwo, StyledHelmet } from './styles';

const DropOffChooseMeal = () => {
  const params = useSearchParams();
  const selectedDate = params[0].get('date') ?? new Date().toISOString();
  // const selectedCategoryParam = params[0].get('selectedCategory');

  const _eventId = params[0].get('eventId');

  const [isSaveForLater, setSaveForLater] = useState<boolean>(false);
  const [weeklyMenu, setWeeklyMenu] = useState<WeeklyMenuOrder>();
  const [menuBoxes, setMenuBoxes] = useState<Array<Box>>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number | string>('0');
  const [allDishes, setAllDishes] = useState<Record<string, Record<string, WeeklyDish[]>>>({});
  const [category, setCategory] = useState<Record<MealType, Category[]>>();
  const [searchText, setSearchText] = useState<string>('');
  const [eventId, setEventId] = useState<string>('');
  const [isNextDisable, setIsNextDisable] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const [selectedMealType, setSelectedMealType] = useState<number>(getMealTypeIndex({ type: 'lunch' }));
  const [selectedSubCategory, setSelectedSubCategory] = useState<number>(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { data: settings } = useFetchSettings<Settings>({});

  const {
    setIsLoginModalOpen,
    otpModalProps,
    headingOutModalProps,
    loginModalProps,
    phoneNumber: enteredPhoneNumber,
  } = useAuthentication();

  const { isUserAuthenticated, phoneNumber } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  const defaultBooking = useSelector((state: ReduxProps) => state?.bookingDetailsReducer?.booking);

  const { fetchEventData } = useFetchEventData();

  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const createDropOffEvent = async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      const _params = {
        emirate: 'dubai',
        numberOfPerson: 1,
        makeMyOwn: true,
        packageDetails: {
          service: 'drop-off',
          name: 'BUILD MY OWN',
        },
        contactDetails: {
          email: clientEmail,
          phoneNumber: clientPhoneNumber,
          name: {
            firstName: firstName,
            lastName: lastName,
          },
        },
        customerId: clientId,
      } as unknown as CreatePackagesEventParams;

      createPackagesEvent(_params).then(res => {
        dispatch(createEventAction(res?.data as Event));

        if (res?.data?._id && selectedDate) {
          setEventId(res?.data?._id);
        }
      });
    } catch (error) {
      console.error(error);
      const errorContent = getErrorMessage(error);
      console.error('Error creating drop-off event:', errorContent);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  useEffect(() => {
    dispatch(inOrderJourney());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dropoffMenuResponse = await getDropoffMenu();

        if (dropoffMenuResponse) {
          setWeeklyMenu(dropoffMenuResponse.data.menu);
          setMenuBoxes((dropoffMenuResponse.data.boxes as Box[]).filter(box => box?.active));

          const lunch = dropoffMenuResponse.data.categories.lunch;
          (lunch as Category[]).splice(0, 0, {
            name: 'Boxes',
            sequence: 0,
          });
          setCategory(dropoffMenuResponse.data.categories);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!_eventId) {
      createDropOffEvent();
      return;
    } else {
      fetchEventData({
        id: _eventId,
        skipErrorMessage: true,
        onError: () => {
          console.log('Event Fetch Failed');
        },
        params: {
          options: {
            eventCategories: true,
          },
        },
      });
    }

    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearBookingAction());
    };
  }, []);

  const {
    Bookings,
    _id,
    makeMyOwn,
    numberOfPerson,
    packageDetails: packageData,
    eventStatus,
    startDate: eventStartDate,
  } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const {
    email: clientEmail,
    firstName,
    lastName,
    phoneNumber: clientPhoneNumber,
    _id: clientId,
  } = useSelector((state: ReduxProps) => state?.clientDetailsReducer);

  const dropoffEditMode = packageData.service === DROP_OFF && !!_eventId;

  const [updatedBooking, setUpdatedBooking] = useState<Booking>({} as Booking);

  useEffect(() => {
    if (Bookings && Bookings?.length > 0) {
      const matchingBooking = Bookings.find(booking => booking.eventId === _eventId);
      matchingBooking && setUpdatedBooking({ ...matchingBooking });
    }
  }, [Bookings, _eventId, setUpdatedBooking]);

  const serviceType = DROP_OFF;

  const getMealLabel = (meal: MealType) => {
    switch (meal) {
      case 'break':
        return 'Break';
      case 'breakfast':
        return 'Breakfast';
      case 'lunch':
        return 'Lunch/Dinner';
      default:
        return 'Breakfast';
    }
  };

  const refetchAndSaveEvent = async (id: string) => {
    if (_id) {
      const response = await getEventById({}, _id);

      if (response.status === statusCodes.API_SUCCESS_CODE) {
        const result = response?.data;
        result && dispatch(updateEventAction(result));
      }
    }

    setEventId(id);
  };

  const handleBookingUpdate = async () => {
    const loaderRequest = `unique_${Date.now()}`;
    const bookingId = eventId;
    setIsNextDisable(true);

    const selectedBooking = dropoffEditMode
      ? defaultBooking?.length > 0
        ? defaultBooking[0]
        : updatedBooking
      : defaultBooking?.length > 0
      ? defaultBooking[0]
      : ({} as Booking);

    const dishes: WeeklyDishPayload[] = [];
    const addOns: WeeklyAddonPayload[] = [];
    const boxes: BoxPayload[] = [];

    try {
      dispatch(startLoaderAction(loaderRequest));
      selectedBooking?.addOns?.forEach(addon => {
        addOns.push({
          category: addon?.category?._id as string,
          includedInPackage: makeMyOwn ? false : !!addon?.includedInPackage,
          itemCount: addon?.itemCount || 1,
          name: addon?.name,
          price: addon?.price,
          quantity: addon?.quantity,
          addOnId: addon?.addOnId,
        });
      });
      selectedBooking?.dishes?.forEach(dish => {
        dishes.push({
          category: dish?.category?._id as string,
          includedInPackage: makeMyOwn ? false : !!dish?.includedInPackage,
          itemCount: dish?.itemCount || (1 as number),
          name: dish?.name,
          dishId: dish?.dishId,
          price: getDishPrice(dish, serviceType),
          quantity: getDishQuantity(dish, serviceType),
        });
      });

      if (dropoffEditMode) {
        selectedBooking?.boxes?.forEach(box => {
          const boxId = box.boxId as Box;
          boxes.push({
            itemCount: box?.itemCount || (1 as number),
            name: boxId?.name as string,
            price: boxId?.price,
            boxId: boxId?._id as string,
            sapId: boxId?.sapId as string,
            quantity: 1,
          });
        });
      } else {
        selectedBooking?.boxes?.forEach(box => {
          boxes.push({
            itemCount: box?.itemCount || (1 as number),
            name: box?.name as string,
            price: box?.price,
            boxId: box?._id as string,
            sapId: box?.sapId as string,
            quantity: 1,
          });
        });
      }

      const newBooking = {
        eventId: _id,
        dishes,
        boxes: boxes,
        bookingDate: dateFormatter(new Date().toISOString()),
        extra: false,
        packageDetails: {
          name: 'BUILD MY OWN',
          packageId: makeMyOwn ? undefined : packageData?.packageId,
          service: packageData?.service,
          type: 'lunch',
        },
        makeMyOwn: makeMyOwn,
        bookingTime: new Date().toISOString(),
      } as CreateBookingProps;

      if (!dropoffEditMode) {
        return await createBooking(newBooking)
          .then(async res => {
            dispatch(updateBookingAction([...(defaultBooking as Booking[])]));
            await refetchAndSaveEvent(res?._id);
          })
          .catch(error => error);
      } else {
        return await updateBookingById(
          updatedBooking._id as string,
          getUpdateBody({
            setData: {
              dishes,
              ...(addOns?.length ? { addOns: addOns } : { addOns: [] }),
              ...(boxes?.length ? { boxes: boxes } : { boxes: [] }),
            },
          })
        )
          .then(async () => {
            await refetchAndSaveEvent(bookingId);
          })
          .catch(error => error);
      }
    } finally {
      setIsNextDisable(false);
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const headingOutOtpProps = {
    ...otpModalProps,
    // onSave: () => {
    //   //
    // },
    message: `We’ve sent a One-Time Password (OTP) to your mobile number ending with ***${enteredPhoneNumber?.slice(
      -3
    )}. Please input the OTP provided to verify your identity and proceed with saving for later.`,
  };

  const handleNext = debounce(
    useCallback(() => {
      handleBookingUpdate()
        .then(async res => {
          if (res?.name !== 'AxiosError' && (dropoffEditMode ? _eventId : eventId)) {
            navigate(UiRoutes.DELIVERY.replace(':id', dropoffEditMode ? _eventId : eventId));
          }
        })
        .catch(err => {
          console.log('error', err);
        });
    }, [navigate, handleBookingUpdate]),
    0
  );

  const handleDishItemCount = useCallback(
    ({ id, itemCount, category: catName }: { itemCount: number; id: string; category: string }) => {
      // Determining the current booking data based on the mode
      const curBooking = dropoffEditMode ? updatedBooking : defaultBooking[0];

      const isBox = isBoxCategory(catName);

      if (isBox) {
        // Updating the boxes array with the modified current box item
        const updatedBoxes = curBooking.boxes.map(box => {
          if (dropoffEditMode) {
            return (box.boxId as Box)._id === id ? { ...box, itemCount: itemCount } : box;
          } else {
            return box._id === id ? { ...box, itemCount: itemCount } : box;
          }
        });

        const filteredBoxes = dropoffEditMode
          ? curBooking.boxes?.filter(({ boxId }) => (boxId as Box)._id && (boxId as Box)._id !== id) || []
          : curBooking.boxes?.filter(({ _id }) => _id && _id !== id) || [];

        // Preparing the updated booking data based on mode
        const updatedBookingData = dropoffEditMode
          ? {
              ...updatedBooking,
              ...{
                boxes: itemCount <= 0 ? filteredBoxes : updatedBoxes,
              },
            }
          : {
              ...defaultBooking[0],
              ...{
                boxes: itemCount <= 0 ? filteredBoxes : updatedBoxes,
              },
            };

        // Dispatching the action to update the booking in Redux
        dispatch(updateBookingAction([updatedBookingData]));

        // Updating local state if in drop-off edit mode to be in sync with Redux
        setUpdatedBooking(prev => ({
          ...prev,
          ...{
            boxes: itemCount <= 0 ? filteredBoxes : updatedBoxes,
          },
        }));
      } else {
        // Updating the dishes array with the modified current dish item
        const updatedDishes = curBooking.dishes
          ?.filter(dish => dish)
          ?.map(dish => (dish.dishId === id ? { ...dish, itemCount } : dish));

        const filteredDishes = curBooking.dishes?.filter(dish => dish?.dishId !== id) || [];

        // Preparing the updated booking data based on mode
        const updatedBookingData = dropoffEditMode
          ? {
              ...updatedBooking,
              ...{ dishes: itemCount <= 0 ? [...filteredDishes] : [...updatedDishes] },
            }
          : {
              ...defaultBooking[0],
              ...{ dishes: itemCount <= 0 ? [...filteredDishes] : [...updatedDishes] },
            };

        // Dispatching the action to update the booking in Redux
        dispatch(updateBookingAction([updatedBookingData]));

        // Updating local state if in drop-off edit mode to be in sync with Redux
        setUpdatedBooking(prev => ({
          ...prev,
          ...{ dishes: itemCount <= 0 ? filteredDishes : updatedDishes },
        }));
      }
    },
    [dispatch, dropoffEditMode, updatedBooking, defaultBooking, selectedSubCategory]
  );

  const getBookingDishes = () => {
    if (dropoffEditMode) {
      return defaultBooking?.length !== 0 ? defaultBooking?.[0]?.dishes : Bookings?.[0]?.dishes || [];
    } else {
      return defaultBooking?.length > 0 ? defaultBooking?.[0]?.dishes : [];
    }
  };

  const getBookingAddons = () => {
    if (dropoffEditMode) {
      return defaultBooking?.length !== 0 ? defaultBooking?.[0]?.dishes : Bookings?.[0]?.dishes || [];
    } else {
      return defaultBooking?.length > 0 ? defaultBooking?.[0]?.dishes : [];
    }
  };

  const getBookingBoxes = () => {
    if (dropoffEditMode) {
      return defaultBooking?.length !== 0
        ? defaultBooking?.[0]?.boxes.map(box => {
            return {
              _id: (box.boxId as Box)._id,
              name: (box.boxId as Box).name,
              sapId: (box.boxId as Box).sapId,
              category: BOXES_CATEGORY,
              price: (box.boxId as Box).price,
              itemCount: box.itemCount,
            } as Box;
          })
        : Bookings?.[0]?.boxes.map(box => {
            return {
              _id: (box.boxId as Box)._id,
              name: (box.boxId as Box).name,
              sapId: (box.boxId as Box).sapId,
              category: BOXES_CATEGORY,
              price: (box.boxId as Box).price,
              itemCount: box.itemCount,
            } as Box;
          }) || [];
    } else {
      return defaultBooking?.length > 0
        ? defaultBooking?.[0]?.boxes
            .filter(box => box)
            ?.map(box => {
              return {
                ...box,
                category: BOXES_CATEGORY,
              };
            })
        : [];
    }
  };

  const handleCheckbox = useCallback(
    ({
      isChecked,
      id,
      selectItemCount,
      category: dishCategory,
    }: {
      isChecked: boolean;
      id: string;
      selectItemCount?: number;
      category: string;
    }) => {
      if (!weeklyMenu) return;

      const isAddon = isAddonCategory(dishCategory);
      const isBox = isBoxCategory(dishCategory);

      const selectedMeal: string = category ? getMealKeyFromIndex(selectedMealType) : 'lunch';

      if (isChecked) {
        const selectedDish = isAddon
          ? weeklyMenu.addOnMenu?.addOns.find(addOn => addOn?._id === id)
          : isBox
          ? null
          : weeklyMenu?.[selectedMeal as MealType]?.dishes.find(dish => dish?._id === id);

        const selectedBox = !isBox ? null : menuBoxes?.find(box => box?._id === id);

        const box = selectedBox
          ? ({
              _id: selectedBox?._id as string,
              name: selectedBox?.name as string,
              description: selectedBox?.description as string,
              price: selectedBox?.price as number,
              sapId: selectedBox?.sapId as string,
              category: BOXES_CATEGORY,
              itemCount: selectItemCount || selectedBox?.itemCount,
            } as Box)
          : null;

        const dish = selectedDish
          ? ({
              addOn: false,
              category: {
                _id: selectedDish?.category?._id,
                name: selectedDish?.category?.name,
                sequence: selectedDish?.category?.sequence,
              },
              dishId: selectedDish?._id as string,
              name: selectedDish?.name as string,
              price: selectedDish?.price as number,
              dropOffPrice: selectedDish?.dropOffPrice as number,
              quantity: selectedDish?.quantity as number,
              dropOffQuantity: selectedDish?.dropOffQuantity as number,
              sapId: '',
              subCategory: '',
              itemCount: selectItemCount || selectedDish?.itemCount,
              includedInPackage: true,
            } as Dish)
          : null;

        const addon = isAddon
          ? ({
              addOnId: selectedDish?._id as string,
              category: {
                _id: selectedDish?.category?._id,
                name: selectedDish?.category?.name,
                sequence: selectedDish?.category?.sequence,
              },
              name: selectedDish?.name as string,
              price: selectedDish?.price as number,
              quantity: selectedDish?.quantity as number,
              sapId: '',
              itemCount: selectItemCount,
              subCategory: { _id: selectedDish?.subCategory?._id, name: selectedDish?.subCategory?.name },
              includedInPackage: true,
            } as AddOn)
          : null;

        if (dropoffEditMode) {
          const { dishes: dishesArr, boxes: boxesArr } = updatedBooking; // from the server

          // loop, if found, update itemCount
          // and if not found push unto the array
          let matchFound = false;

          if (!isBox && dishesArr) {
            const updatedCurrentDishes = dishesArr.map(_dish => {
              if (_dish.dishId === id) {
                matchFound = true;
                return { ..._dish, itemCount: selectItemCount }; // working
              }

              return _dish;
            });

            const newDishesArr = matchFound ? updatedCurrentDishes : [...updatedCurrentDishes, dish];

            setUpdatedBooking({ ...updatedBooking, dishes: newDishesArr as Dish[] });
            dispatch(updateBookingAction([{ ...updatedBooking, dishes: newDishesArr as Dish[] }]));
          }

          if (isBox && boxesArr) {
            const modifiedBox = box
              ? {
                  boxId: {
                    _id: box?._id,
                    name: box?.name,
                    price: box?.price,
                    sapId: box?.sapId,
                    category: BOXES_CATEGORY,
                  },
                  itemCount: box?.itemCount,
                  price: box?.price,
                }
              : null;

            const updatedCurrentBoxes = boxesArr.map(_box => {
              if ((_box?.boxId as Box)?._id === id) {
                matchFound = true;
                return { ..._box, itemCount: selectItemCount };
              }

              return _box;
            });

            const newBoxesArr = matchFound ? updatedCurrentBoxes : [...updatedCurrentBoxes, modifiedBox];

            setUpdatedBooking({ ...updatedBooking, boxes: newBoxesArr as Box[] });
            dispatch(updateBookingAction([{ ...updatedBooking, boxes: newBoxesArr as Box[] }]));
          }
        } else {
          const copyBooking: Booking = {
            eventId: _id || '',
            dishes: isAddon ? [] : isBox ? [] : [dish as Dish],
            bookingDate: dateFormatter(eventStartDate || new Date().toISOString()),
            extra: false,
            addOns: isAddon ? [addon as AddOn] : [],
            numberOfPerson,
            packageDetails: {
              name: 'BUILD MY OWN',
              packageId: undefined,
              service: DROP_OFF,
              type: 'lunch',
            },
            _id: eventId,
            makeMyOwn: true,
            bookingTime: new Date().toISOString(),
            boxes: isBox ? [box as Box] : [],
          };

          dispatch(
            updateBookingAction(
              defaultBooking?.some(defBooking => {
                return defBooking.eventId === eventId;
              })
                ? (defaultBooking?.map(item => {
                    if (item?.eventId === eventId) {
                      return {
                        ...item,
                        ...(isAddon
                          ? { addOns: [...(item?.addOns ?? []), addon] }
                          : isBox
                          ? { boxes: [...(item?.boxes ?? []), box] }
                          : { dishes: [...(item?.dishes ?? []), dish] }),
                      };
                    } else {
                      return item;
                    }
                  }) as Booking[])
                : [...(defaultBooking ?? []), copyBooking || []]
            )
          );
        }
      } else {
        if (packageData.service !== DROP_OFF) {
          dispatch(
            updateBookingAction(
              defaultBooking?.map(item => {
                if (item?._id === eventId) {
                  const addOns = item?.addOns?.filter(({ addOnId }) => addOnId !== id);
                  const dishes = item.dishes?.filter(({ dishId }) => dishId !== id);

                  return {
                    ...item,
                    ...(isAddon ? { addOns: addOns?.length ? addOns : [] } : { dishes: dishes?.length ? dishes : [] }),
                  };
                } else {
                  return item;
                }
              })
            )
          );
        } else {
          // Determining the current booking data based on the mode
          const curBooking = dropoffEditMode ? updatedBooking : defaultBooking[0];

          if (!curBooking) return;

          // Filter out the removed items
          const filteredAddOns = curBooking.addOns?.filter(({ addOnId }) => addOnId !== id) || [];
          const filteredDishes = curBooking.dishes?.filter(({ dishId }) => dishId !== id) || [];

          const filteredBoxes = curBooking.boxes?.filter(box => box._id !== id) || [];

          // Dispatch the action to update the booking in Redux
          dispatch(
            updateBookingAction([
              {
                ...updatedBooking,
                dishes: filteredDishes ?? [],
                boxes: filteredBoxes,
              },
            ])
          );

          // Update local state if in dropoffEditMode to stay in sync with Redux
          setUpdatedBooking({ ...updatedBooking, dishes: filteredDishes, boxes: filteredBoxes });
        }
      }
    },
    [
      weeklyMenu,
      getBookingDishes,
      defaultBooking,
      eventId,
      updateBookingAction,
      getBookingAddons,
      serviceType,
      updatedBooking,
      getBookingBoxes,
      setUpdatedBooking,
    ]
  );

  useEffect(() => {
    const handleScroll = debounce(() => {
      const sections = document.querySelectorAll('.dishScroll');
      sections.forEach(section => {
        if (isElementInViewport(section as HTMLElement)) {
          setSelectedSubCategory(() => Number(section.id.replace('dish', '')));
        }
      });
    }, 25);

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  // const getSelectedCategoryIndexFromName = () => {
  //   return category?.[getMealKeyFromIndex(selectedMealType) as MealType].findIndex(cat => {
  //     return cat.name.toLowerCase() === selectedCategoryParam?.replace('+', '\b');
  //   });
  // };

  const getTotalBookingAmount = () => {
    if (packageData.service === DROP_OFF) {
      return getTotalAmount(defaultBooking?.length > 0 ? defaultBooking : Bookings!, true);
    }

    return getTotalAmount(defaultBooking, makeMyOwn);
  };

  const bookingTotal = getTotalBookingAmount();

  const orderFooterProps = useMemo(() => {
    return {
      isDropOffEvent: true,
      amount: formatNumber(totalAmount, true),
      eventStatus: eventStatus?.name,
      nextButtonText: 'Next',
      isButton: true,
      isDisable: isNextDisable,
      handleSave: () => {
        if (isUserAuthenticated) {
          setIsModalOpen(true);
        } else {
          setSaveForLater(true);
          setIsLoginModalOpen(true);
        }
      },

      handleNext: () => (isUserAuthenticated ? handleNext() : setIsLoginModalOpen(true)),
      setCartModalOpen: setIsCartModalOpen,
    };
  }, [totalAmount, makeMyOwn, settings, handleNext]);

  // useEffect(() => {
  //   if (selectedCategoryParam && selectedCategoryParam?.length > 0) {
  //     const selectedCat = getSelectedCategoryIndexFromName() ?? 0;
  //     setSelectedSubCategory(selectedCat);
  //     scrollToCategory(selectedCat);
  //   }
  // }, [selectedCategoryParam, category]);

  const setCategoriesDishesBySubCategory = useCallback(async () => {
    const addAddonsToCategories = (addon: WeeklyDish) => {
      if (addon) {
        const categoryName = addon.category?.name;
        const subcategoryName = addon.subCategory?.name ?? 'noSub';

        if (categoryValues[categoryName]) {
          if (categoryValues[categoryName][subcategoryName]) {
            categoryValues[categoryName][subcategoryName].push(addon);
          } else {
            categoryValues[categoryName][subcategoryName] = categoryValues[categoryName][subcategoryName] || [];
          }
        } else {
          categoryValues[categoryName] = categoryValues[categoryName] || {};
        }
      }
    };

    const addDishesToCategories = (dish: WeeklyDish) => {
      if (dish) {
        const categoryName = dish.category?.name;
        const subcategoryName = dish.subCategory?.name ?? 'noSub';

        categoryValues[categoryName] = categoryValues[categoryName] || {};
        categoryValues[categoryName][subcategoryName] = categoryValues[categoryName][subcategoryName] || [];
        categoryValues[categoryName][subcategoryName].push(dish);
      }
    };

    const addBoxesToCategories = (box: Box) => {
      if (box) {
        const categoryName = 'Boxes';
        const subcategoryName = 'noSub';

        categoryValues[categoryName] = categoryValues[categoryName] || {};
        categoryValues[categoryName][subcategoryName] = categoryValues[categoryName][subcategoryName] || [];
        categoryValues[categoryName][subcategoryName].push({
          ...box,
          category: {
            name: categoryName,
            sequence: 0,
          },
        } as WeeklyDish);
      }
    };

    const bookingDishes = getBookingDishes();
    const bookingAddons = getBookingAddons();

    const categoryValues: Record<string, Record<string, WeeklyDish[]>> = {};
    const _selectedMealType = category ? getMealKeyFromIndex(selectedMealType) : 'lunch';

    if (weeklyMenu) {
      const { dishes = [] } = weeklyMenu[_selectedMealType as MealType];

      // const addOns = weeklyMenu.addOnMenu.addOns ?? [];

      menuBoxes?.forEach(box => {
        const searchedNameText = box?.name?.toLowerCase().includes(searchText);
        const searchedDescriptionText = box?.description?.toLowerCase().includes(searchText);

        if (searchedNameText ?? searchedDescriptionText) {
          addBoxesToCategories(box);
        }
      });

      dishes.forEach(dish => {
        const searchedNameText = dish.name.toLowerCase().includes(searchText);
        const searchedDescriptionText = dish?.description?.toLowerCase().includes(searchText);

        if (searchedNameText ?? searchedDescriptionText) {
          addDishesToCategories(dish);
        }
      });

      // addOns?.forEach(addon => {
      //   const searchedNameText = addon?.name?.toLowerCase().includes(searchText);
      //   const searchedDescriptionText = addon?.description?.toLowerCase().includes(searchText);

      //   if (searchedNameText ?? searchedDescriptionText) {
      //     addAddonsToCategories(addon);
      //   }
      // });
    }

    setAllDishes(categoryValues);
  }, [weeklyMenu, searchText]);

  const getDishItemCount = (dishItemId: string, categoryName: string): number => {
    const isAddon = isAddonCategory(categoryName ?? '');
    const isBox = isBoxCategory(categoryName ?? '');

    const dishes = isAddon ? getBookingAddons() : isBox ? getBookingBoxes() : getBookingDishes();

    const currentDish = isBox
      ? (dishes as Box[])?.find(box => box._id === dishItemId && makeMyOwn)?.itemCount || 0
      : isAddon
      ? (dishes as AddOn[])?.find(addOn => addOn.addOnId === dishItemId && makeMyOwn)?.itemCount || 0
      : (dishes as Dish[])?.find(_dish => _dish && _dish.dishId === dishItemId && makeMyOwn)?.itemCount || 0;

    return currentDish;
  };

  // set dishes according to Category's SubCategory
  useEffect(() => {
    const loaderRequest = `unique_${Date.now()}`;

    if (category) {
      dispatch(startLoaderAction(loaderRequest));
      setCategoriesDishesBySubCategory().finally(() => {
        dispatch(stopLoaderAction(loaderRequest));
      });
    }
  }, [category, weeklyMenu, searchText, selectedMealType]);

  const currentBooking =
    _eventId !== null
      ? Bookings && Bookings?.find(item => item?.eventId === _eventId)
      : defaultBooking?.find(item => item?.eventId === _id);

  const scrollToCategory = (index: number) => {
    setSelectedSubCategory(index);

    document.getElementById(`dish${index}`)?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const pageNumbers = document.querySelectorAll('.dishScroll').length;

    if (index + 1 > pageNumbers) {
      setSelectedSubCategory(0);
    }
  };

  const handleHeadingOutVerify = useCallback(async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));
      await handleBookingUpdate();

      navigate(UiRoutes.ORDER_MANAGEMENT);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  }, [phoneNumber, enteredPhoneNumber, handleBookingUpdate]);

  const BreadCrumb = ({ title, crumbs }: { title: string; crumbs: string[] }) => (
    <BreadCrumbContainer>
      <img
        src={dropoffHeaderIcon}
        alt='circle-top-up'
        style={{
          height: '30px',
          width: '30px',
          marginRight: '8px',
        }}
      />
      {/* <BreadCrumbText style={{ fontWeight: 400, marginRight: '2px' }}>
        {title}
        {crumbs.length > 0 ? <BreadCrumbText>{' / ' + crumbs.join(' / ')}</BreadCrumbText> : null}
      </BreadCrumbText> */}
      <BreadCrumbText style={{ fontWeight: 400, marginRight: '2px' }}>{title}</BreadCrumbText>
    </BreadCrumbContainer>
  );

  return (
    <StyledMainWrapper>
      <MaxWidthContainer extraStyle={{ flexDirection: 'column' }} isMarginBottom>
        <StyledTabs
          value={selectedMealType}
          onChange={() => {}}
          sx={{
            margin: 'auto',
            display: 'none',
            visibility: 'hidden',
            justifyContent: 'center',
            [`&& .${tabClasses.root}`]: {
              flex: 'initial',
            },
            '& .MuiTabs-indicatorSpan': {
              maxWidth: 82,
              width: '100%',
              backgroundColor: '#698EA5',
              borderRadius: '4px 4px 0 0',
              bottom: 0,
            },
          }}
        >
          {category &&
            Object.keys(category).map(label => {
              return (
                <StyledTab
                  sx={{
                    fontSize: '16px',
                    padding: '8px',
                    fontFamily: theme.fontFamily.lato,
                    color: theme.primaryColor.shadePurple,

                    '&.Mui-selected': {
                      color: '#698EA5',
                      fontWeight: 700,
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#698EA5',
                      fontWeight: 700,
                    },
                  }}
                  label={getMealLabel(label as MealType)}
                />
              );
            })}
        </StyledTabs>
        {category && packageData.service === 'drop-off' && (
          <BreadCrumb
            title={'Drop-Off Catering Menu'}
            crumbs={[capitalizeFirstLetterOnly(Object.keys(category)[selectedMealType] as MealType)]}
          />
        )}
        <StyledContentWrapper style={{ flexDirection: 'column' }}>
          <StyledTabs
            value={selectedSubCategory}
            onChange={(_, index) => {
              scrollToCategory(index);
            }}
            sx={{
              display: 'flex',
              position: 'sticky',
              top: '50px',
              zIndex: 1000,

              padding: '0 16px',
              borderRadius: '8px',
              backgroundColor: '#D7E9F0',
              '& .MuiTabs-scroller': {
                overflowX: isMobile ? 'scroll !important' : 'hidden',
              },
              '& .MuiTabs-indicatorSpan': {
                maxWidth: 82,
                width: '100%',
                backgroundColor: '#FCB326',
                borderRadius: '4px 4px 0 0',
                bottom: 0,
              },
            }}
          >
            {category &&
              Object.values(category[getMealKeyFromIndex(selectedMealType) as MealType]).map(item => {
                return (
                  <StyledTab
                    sx={{
                      fontSize: '16px',
                      padding: '8px',
                      fontFamily: theme.fontFamily.lato,
                      color: theme.primaryColor.shadePurple,
                      fontWeight: 600,
                      '&.Mui-selected': {
                        fontWeight: 700,
                      },
                      '&.Mui-focusVisible': {
                        fontWeight: 700,
                      },
                    }}
                    label={(item as Category).name}
                  />
                );
              })}
          </StyledTabs>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <StyledColumnOne $isDesktop={!isMobile} $gap='24px'>
              {isMobile ? (
                <OrderList
                  setTotalAmount={setTotalAmount}
                  isRealtimeBooking
                  settings={settings}
                  selectedBooking={eventId}
                  isScrollable
                  mobileDropoff={isMobile}
                  dropoffEditMode={dropoffEditMode}
                />
              ) : null}

              <StyledCenteredFlexColumn>
                <StyledHelmet>
                  <ChooseMealHelmet
                    setWeeklyMenu={setWeeklyMenu}
                    selectedDate={selectedDate as string}
                    activeStep={0}
                    eventId={_id as string}
                    serviceType={serviceType}
                    setSearchText={setSearchText}
                  />
                </StyledHelmet>
                <DropoffFoodContainer
                  allDishes={allDishes}
                  serviceType={serviceType}
                  selectedDish={
                    [...(getBookingBoxes() || []), ...(getBookingAddons() || []), ...(getBookingDishes() || [])] as
                      | Box[]
                      | AddOn[]
                      | Dish[]
                  }
                  isRateUs={false}
                  getDishItemCount={getDishItemCount}
                  handleDishItemCount={handleDishItemCount}
                  handleCheckbox={handleCheckbox}
                />
              </StyledCenteredFlexColumn>
            </StyledColumnOne>
            {!isMobile ? (
              <StyledColumnTwo>
                <OrderList
                  setTotalAmount={setTotalAmount}
                  isRealtimeBooking
                  settings={settings}
                  selectedBooking={eventId}
                  mobileDropoff={isMobile}
                  isScrollable
                  dropoffEditMode={dropoffEditMode}
                  showDropOffDeliveryFee={packageData.service === DROP_OFF ? false : true}
                />
              </StyledColumnTwo>
            ) : null}
          </div>
        </StyledContentWrapper>
      </MaxWidthContainer>

      {currentBooking?.dishes?.length || currentBooking?.boxes?.length ? <OrderFooter {...orderFooterProps} /> : <></>}
      {isModalOpen && (
        <DeleteModal
          isOpen={isModalOpen}
          modalName={'Are you sure you want to exit? Your progress will be saved.'}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          onSave={() => {
            handleHeadingOutVerify();
          }}
        />
      )}
      <SaveProgressModal {...(isSaveForLater ? { ...headingOutModalProps, setSaveForLater } : loginModalProps)} />
      <OtpVerificationModal {...(isSaveForLater ? { ...headingOutOtpProps, setSaveForLater } : otpModalProps)} />
      {isCartModalOpen && isMobile && (
        <CartModal
          isOpen={isCartModalOpen}
          handleClose={() => setIsCartModalOpen(false)}
          children={
            <OrderList
              setTotalAmount={setTotalAmount}
              isRealtimeBooking
              settings={settings}
              selectedBooking={eventId}
              isScrollable
              dropoffEditMode={dropoffEditMode}
              showDropOffDeliveryFee={packageData.service === DROP_OFF ? false : true}
              mobileDropoff={true}
            />
          }
        />
      )}
    </StyledMainWrapper>
  );
};

export default DropOffChooseMeal;

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '8px',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 82,
    width: '100%',
    backgroundColor: '#3790B3',
    borderRadius: '4px 4px 0 0',
    bottom: 0,
  },
});

interface StyledTabProps {
  label: string;
}

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(() => ({
  textTransform: 'none',
  fontFamily: 'Lato',
  fontWeight: 500,
  fontSize: '20px',
  marginRight: '24px',
  padding: '16px 16px 20px 16px',
  color: '#000000',
  '&.Mui-selected': {
    color: '#3790B3',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#3790B3',
  },
}));
